var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"custom-dashboard-notifications"},[_c('div',{staticClass:"mr-2 mt-1 d-flex",staticStyle:{"justify-content":"space-between"}},[_c('div',[_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.component.component_name))])]),_c('div',[(!_vm.hide_options)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('el-link',{staticStyle:{"transform":"rotate(90deg)","height":"40px"},attrs:{"icon":"el-icon-more","underline":false}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{on:{"click":_vm.editComponent}},[_c('el-dropdown-item',[_vm._v("Edit")])],1),_c('a',{on:{"click":_vm.deleteComponent}},[_c('el-dropdown-item',[_vm._v("Delete")])],1)])],1):_vm._e()],1)]),(_vm.notificationsData.length)?_c('div',{staticClass:"notifications-container"},[_c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"mb-1 mr-1 ml-1"},[_vm._l((_vm.notificationsData),function(notification,index){return _c('div',{key:index,on:{"click":function($event){return _vm.redirect(notification)}}},[_c('el-card',{staticClass:"custom-card",style:(`margin-bottom: 5px; border-radius: 3px; background-color:${_vm.hexToRgb(
              notification.background_color,
              0.1
            )}; border: 0.1px solid ${notification.background_color}`)},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"notification-icon",style:({
                  '--icon-color': notification.background_color,
                  display: 'flex',
                  'align-items': 'center',
                  padding: '8px',
                  'background-color': _vm.hexToRgb(
                    notification.background_color,
                    0.15
                  ),
                  'border-radius': '5px',
                })},[_c('icons',{attrs:{"iconName":notification.icon}})],1),_c('div',{staticClass:"ml-1"},[_c('h4',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(notification.heading))]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(notification.custom_message))])]),_c('div',{staticClass:"flex-grow-1 text-right"},[_c('span',{staticClass:"date-time text-dark fw-medium pl-0 opacity-80"},[_vm._v(_vm._s(_vm._f("getFormattedDateTypeThree")(notification.updated_at)))])])])])],1)}),(_vm.hasMore)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","text-align":"center","font-size":"16px","font-weight":"bold"},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.loadMoreText)+" ")]):_vm._e()],2)])]):_c('div',[_c('el-empty',{attrs:{"description":"No Notifications"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }